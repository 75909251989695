export default class AjaxStatusRequest
{
    constructor(url, data = {}) {
        this.url = url;
        this.data = data;
        this.statusCallbacks = [];
        this.completionCallbacks = [];
    }

    onStatus(status = 0, callback = request => {}) {
        this.statusCallbacks.push({
            status: status,
            callback: callback
        });

        return this;
    }

    onError(callback = request => {}) {
        return this.onStatus(0, callback);
    }

    onSuccess(callback = request => {}) {
        return this.onStatus(1, callback);
    }

    onComplete(callback = request => {}) {
        this.completionCallbacks.push(callback);

        return this;
    }

    send() {
        $.ajax({
            type: 'post',
            url: this.url,
            data: this.data,
            dataType: 'json',
            error: e => {console.log(e); $('html').html(e.responseText);},
            success: r => {
                let {status, response} = r;

                this.r = r;
                this.status = status;
                this.response = response;

                this.statusCallbacks.forEach(({status, callback}) => {
                    if (status === this.status) callback(this);
                });

                this.completionCallbacks.forEach(callback => callback(this));
            }
        });
    }
}