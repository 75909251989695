export default class PageHandler
{
    constructor() {
        this.data = {};
        this.elements = {
            $body: $('body')
        };

        if (typeof this.init === 'function') {
            this.init();
        }

        if (typeof this.handlers === 'function') {
            this.handlers();
        }
    }

    setData(inputs = {}) {
        for (let key in inputs) {
            if (!inputs.hasOwnProperty(key)) continue;

            this.data[key] = inputs[key];
        }

        return this;
    }

    setElements(inputs = {}) {
        for (let key in inputs) {
            if (!inputs.hasOwnProperty(key)) continue;

            this.elements[key] = inputs[key];
        }

        return this;
    }
}