import PageHandler from '../../core/page-handler';
import AjaxStatusRequest from '../../ajax/status-request';

class HomeHandler extends PageHandler
{
    constructor() {
        super();
    }

    init() {
        let el = this.elements;

        el.$callService = el.$body.find('.call-service');
        el.$name = el.$callService.find('.name');
        el.$email = el.$callService.find('.email');
        el.$tel = el.$callService.find('.tel');
        el.$submit = el.$callService.find('.request-call');
        el.$responseContainer = el.$callService.find('.response-container');
    }

    handlers() {
        let el = this.elements;

        el.$callService.on('submit', ev => {
            ev.preventDefault();

            let name = el.$name.val(),
                email = el.$email.val(),
                tel = el.$tel.val(),
                submitCaption = el.$submit.text();

            if (el.$submit.hasClass('disabled'))
                return;

            el.$name.prop('disabled', true);
            el.$email.prop('disabled', true);
            el.$tel.prop('disabled', true);
            el.$submit.addClass('active').html('<i>Bitte warten...</i>');

            el.$responseContainer.html(null);

            this.submitMessage(name, email, tel)
                .onError(request => {
                    el.$responseContainer.html(`
                        <div class="alert alert-danger alert-dismissible m-t call-request-response">
                            <button class="close" data-dismiss="alert" aria-label="Ausblenden">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <span style="text-transform: none;">
                                ${request.response}
                            </span>
                        </div>
                    `);

                    el.$submit.removeClass('active').text(submitCaption);
                })
                .onSuccess(request => {
                    el.$responseContainer.html(`
                        <div class="alert alert-success alert-dismissible m-t call-request-response">
                            <button class="close" data-dismiss="alert" aria-label="Ausblenden">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <span style="text-transform: none;">
                                ${request.response}
                            </span>
                        </div>
                    `);

                    el.$submit.remove();
                    el.$callService.find('label').remove();
                })
                .send();
        });
    }

    submitMessage(name, email, tel) {
        return new AjaxStatusRequest('/api/support/request-call-service', {
            name, email, tel
        });
    }
}

$(() => new HomeHandler);
